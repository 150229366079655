import React from 'react'
import './ShareFour.css'
import {
    FacebookMessengerShareButton,
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
  } from "react-share";
const ShareFour = ({data}) => {
  return (
    <div className='ShareFour container'>
        <h4>شارك الكود علي</h4>
        <div className='ShareFour_btns'>
        <FacebookShareButton url={data?.link_en}>
          <i className="ri-facebook-circle-fill" />
        </FacebookShareButton >
        <FacebookMessengerShareButton url={data?.link_en}>
          <i className="ri-messenger-line"></i>
        </FacebookMessengerShareButton>
        <WhatsappShareButton url={data?.link_en}>
          <i className="ri-whatsapp-line" />
        </WhatsappShareButton>
        <TwitterShareButton url={data?.link_en}>
          <i className="ri-twitter-x-fill" />
        </TwitterShareButton>
        </div>
    </div>
  )
}

export default ShareFour