import React from 'react'
import './HeadCoupon.css'
// import logo from '../../../assets/images/logo.jfif'
const HeadCoupon = ({data}) => {
  return (
    <div className="HeadCoupon">
      <div className="HeadCoupon_title">
        <div className="radiousLogo">
          <img
            src={`https://coupon-lands.com/back-end/${data?.image[0]?.url}`}
            alt="logo"
          />
        </div>
        <h1>
        {data?.name_en} discount code <br /> <span>{data?.coupons?.discount_percentage}%</span> sale
        </h1>
      </div>
      <div className="HeadCoupon_description">
        <p>
        {data?.coupons?.description_en}
        </p>
      </div>
    </div>
  );
}

export default HeadCoupon