import React, { useRef } from "react";
import "./ThreeCopy.css";
import { toast } from "react-toastify";
const ThreeCopy = ({ data }) => {
  const inputRef = useRef(null);
  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand("copy");
    toast.success("code copied successfully");
  };
  return (
    <div className="ThreeCopy">
      {data?.coupons && data?.coupons?.type === "link" ? (
        <div className="ThreeCopy_btn">
          <button className="ThreeCopy_GETBTN getOffer">
            <a href={data?.coupons?.link}>GET OFFER</a>
          </button>
        </div>
      ) : data?.coupons?.type === "code" ? (
        <>
          <div className="ThreeCopy_btn">
            <input
              type="text"
              defaultValue={data?.coupons?.code}
              readOnly
              ref={inputRef}
            />
            <button className="ThreeCopy_GETBTN" onClick={copyToClipboard}>
              GET CODE
            </button>
          </div>
          <a href={data?.link_en} className="visit">
            VISIT WEBSITE
          </a>
        </>
      ) : (
        <div className="ThreeCopy_btn">
          <input
            type="text"
            defaultValue={data?.coupons?.code}
            readOnly
            ref={inputRef}
          />
          <button
            className="ThreeCopy_GETBTN"
            onClick={async () => {
              await copyToClipboard();
              window.location.href = data?.coupons?.link
            }}
          >
            GET CODE
          </button>
        </div>
      )}
    </div>
  );
};

export default ThreeCopy;
