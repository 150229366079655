import React from 'react'
import './NotFound.css';
import { useNavigate } from 'react-router-dom';
const NotFound = () => {
const navigate = useNavigate();
const returnBtn = ()=>{
    navigate('/');
}
  return (
    <div className="not-found-container">
      <h1 className="not-found-title">404 - Not Found</h1>
      <p className="not-found-message">The page you are looking for does not exist.</p>
      <button className='btn btn-lg btn-success' onClick={returnBtn}>Return</button>
    </div>
  )
}

export default NotFound