import React, { useRef } from 'react'
import './ContentCoupon.css'
import { toast } from 'react-toastify';
const ContentCoupon = ({data}) => {
    const inputRef = useRef(null);
    const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand('copy');
        toast.success("code copied successfully")
      };

  return (
    <div className="content container">
      <div className="content_img_header">
        <img
          src={`https://coupon-lands.com/back-end/${data?.image[0]?.url}`}
          alt="logo"
        />
      </div>
      <div className="content_txt">
        <h1 className="content_txt_head  text-center">{data?.name_en}</h1>
        <p className="content_txt_body  text-center">
          {data?.coupons?.description_en}
        </p>
      </div>



      {data?.coupons && data?.coupons?.type === "link" ? (
         <div className="btn_copy">
         <button id="copy">
           <a href={data?.coupons?.link} target='blank'>
               GET OFFER
           </a>
         </button>
        </div>
      ) : data?.coupons?.type === "code" ? (
        <>
          <div className="btn_copy">
          <input
            type="text"
            ref={inputRef}
            defaultValue={data?.coupons?.code}
            readOnly
          />
          <button id="copy" onClick={copyToClipboard}>
            GET CODE
          </button>
        </div>
        <a href={data?.link_en} target="blank">
        VISIT WEBSITE
      </a>
        </>
      ) : (
        
        <div className="btn_copy">
          <input
            type="text"
            ref={inputRef}
            defaultValue={data?.coupons?.code}
            readOnly
          />
          <button id="copy" onClick={async () => {
              await copyToClipboard();
              window.location.href = data?.coupons?.link
            }}>
            GET CODE
          </button>
        </div>
      )}

{/* //////////////////////////////////////////////////////// */}
    </div>
  );
}

export default ContentCoupon