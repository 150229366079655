import React from 'react'
import './Footer.css'
const Footer = (props) => {
  return (
    <footer className={`footert ${props.class}`}>
        <div className='container'>
            <p>All right reserved © 2024</p>
            <div className='links_footer'>
                <a href="##">Term of use </a>
                <span> - </span>
                <a href="##">policy</a>
            </div>
        </div>
    </footer>
  )
}

export default Footer