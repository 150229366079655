import React from 'react'
import './HeadThree.css'
// import imgTest from '../../../assets/images/Logo.png'
const HeadThree = ({data}) => {
  return (
    <div className='headThree'>
        <div className='headThree_title'>
            <div className='headThree_img'>
                <img src={`https://coupon-lands.com/back-end/${data?.image[0]?.url}`} alt='logo'/>
                <span>{data?.coupons?.discount_percentage}%OFF</span>
            </div>
            <h2>{data?.name_en} discount code <br/> {data?.coupons?.discount_percentage}% sale</h2>
        </div>
        <div className='headThree_description'>
            <p>
            {data?.coupons?.description_en}
            </p>
        </div>
    </div>
  )
}

export default HeadThree