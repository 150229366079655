import React, { useContext, useEffect } from 'react'
import './CouponThree.css'
import Footer from '../../components/coupontComponents/footer/Footer'
import HeadThree from '../../components/couponThreeC/HeadTitle/HeadThree'
import ThreeCopy from '../../components/couponThreeC/ThreeCopy/ThreeCopy'
import ThreeInfo from '../../components/couponThreeC/ThreeInfo/ThreeInfo'
import { useNavigate, useParams } from 'react-router-dom'
import { DataContext } from '../../contextApi/DataContext'
import { Helmet } from 'react-helmet-async'
const CouponThree = () => {
    const { id } = useParams();
    const {data,setId,host} = useContext(DataContext);
    const {loading} = useContext(DataContext);
    const navigate = useNavigate()
    useEffect(()=>{
      setId(id)
      if(!loading){
        navigate(`/${id}`, { replace: true });
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])

    
    // console.log(host);
  return (
    <>
      <Helmet>
        <title>yalla Coupon || {`${data?.data?.name_en}`}</title>
        <meta name="description" content={`Discover exclusive discounts at ${data?.data?.name_en}, your go-to destination for unbeatable savings! Browse a diverse range of categories, from fashion to electronics, and access verified coupons for top brands. Enjoy a seamless shopping experience with regularly updated deals. Subscribe to our newsletter for the latest promotions. Shop smart, save more at yalla coupon today!`}/>
    </Helmet>
        <header className='headerThree'>
            <h1>{host}</h1>
        </header>
        { 
          loading &&
          <main className='mainThree'>
              <div className='container'>
                  <HeadThree data={data?.data}/> 
                  <ThreeInfo data={data?.data}/>
                  <ThreeCopy data={data?.data}/>
              </div>
          </main>
        }
        <Footer class={'footerThree'}/>
    </>
  )
}

export default CouponThree